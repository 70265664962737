import {selectPreOrder} from 'shop-packages/redux/checkout/selectors';
import { getValue } from 'shop-packages/redux/connectSelector';
import {getAnalyticsProductFromLocalStorage } from '../../../utils';

const gettext = window.gettext || ((str) => str);

const trans = {
  error: gettext('An error occured, please try again later.'),
  noRedirectUrlError: gettext('An error occured. Please try again later or contact site administrator.')
};

class PayWithIyzicoOption {
  constructor({
    payWithIyzicoFormSelector = '.js-pay-with-iyzico-form',
    completeButtonSelector = '.js-checkout-complete-button',
    agreementInputSelector = '.js-checkout-agreement-input',
    agreementErrorSelector = '.js-pay-with-iyzico-agreement-error',
    formErrorsSelector = '.js-pay-with-iyzico-form-errors'
  }) {
    this.payWithIyzicoForm = document.querySelector(payWithIyzicoFormSelector);
    this.completeButton = this.payWithIyzicoForm.querySelector(completeButtonSelector);
    this.agreementInput = this.payWithIyzicoForm.querySelector(agreementInputSelector);
    this.agreementError = this.payWithIyzicoForm.querySelector(agreementErrorSelector);
    this.formErrors = this.payWithIyzicoForm.querySelector(formErrorsSelector);

    this.payWithIyzicoForm.addEventListener('submit', this.onFormSubmit.bind(this));
  }

  async onFormSubmit(e) {
    e.preventDefault();
    this.onPendingUpdate(true);

    const url = `${window.location.href}?${new URLSearchParams({ page: 'RedirectionPaymentSelectedPage' })}`;

    const csrf = document.cookie
      .split('; ')
      .find(row => row.startsWith('csrftoken='))
      .split('=')[1];

    const body = new URLSearchParams({ agreement: true });

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRFToken': csrf,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body
    });

    if (!response.ok) {
      this.showError();
      this.onPendingUpdate(false);
      return;
    }

    const data = await response.json();
    const errors = data?.errors;
    const redirectUrl = data?.context_list?.[0]?.page_context?.redirect_url;

    if (errors) {
      this.showError();
      this.onPendingUpdate(false);
      return;
    }

    if (!redirectUrl) {
      this.showError(trans.noRedirectUrlError);
      this.onPendingUpdate(false);
      return;
    }

    try {
      this.pushEvent();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error in pushEvent:', error);
    }
    this.onPendingUpdate(false);
    window.location = redirectUrl;
  }

  onPendingUpdate(isPending) {
    const completedButton = $('.js-checkout-complete-button');

    if (!completedButton) {
      return;
    }

    isPending ?
      completedButton
        .attr('disabled', 'disabled')
        .addClass('loading') :
      completedButton
        .removeAttr('disabled')
        .removeClass('loading');
  }

  pushEvent() {
    const ecommerce = JSON.parse($('.analytics-address-data').text() || '{}');
    const { items } = ecommerce;

    if (localStorage.basketAnalytics) {
      const basketAnalytics = JSON.parse(localStorage.basketAnalytics);
      items.forEach(item => {
        const analyticProduct = getAnalyticsProductFromLocalStorage(item.item_id);
        const basketItem = basketAnalytics.find(basketItem => basketItem.item_sku === item.item_sku);
        if (basketItem) {
          item.discount_rate = basketItem?.discount_rate;
          item.item_discount_status = basketItem?.item_discount_status
          item.coupon = basketItem?.coupon;
          item.item_last_price = basketItem?.item_last_price
          item.discount =  basketItem?.discount;
        }
        item.item_list_id =  analyticProduct?.listIdx || '';
        item.item_list_name =  analyticProduct?.listName || ''
      });
    }

    const preOrder = getValue(selectPreOrder);

    const paymentInfo = {
      event: 'add_payment_info',
      ecommerce: {
        ...ecommerce,
        currency: 'TRY',
        payment_type: preOrder.payment_option?.name || 'Kredi Kartı',
        value: Number(preOrder.total_amount)
      }
    };

    if (window.isMobileApp) {
      window.ReactNativeWebView?.postMessage(JSON.stringify(paymentInfo));
      return;
    }

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(paymentInfo);
  }

  showError(error = trans.error) {
    this.formErrors.innerHTML = error;
    this.formErrors.removeAttribute('hidden');
  }

  unmount() {
    this.payWithIyzicoForm.removeEventListener('submit', this.onFormSubmit);
  }
}

export default PayWithIyzicoOption;
