export const objectSelector = (obj, path, defaultParams) => {
  const pathKeys = path.split('.');
  let object = Object.assign({}, obj);

  for (let i = 0; i < pathKeys.length; i++) {
    let key = pathKeys[i];
    if (!object || !Object.prototype.hasOwnProperty.call(object, key)) {
      object = defaultParams;
      break;
    }
    object = object[key];
  }
  return object;
}

export const templateRenderer = (templateHtml, data) => {
  let result = templateHtml;

  for (const key in data) {
    result = result.replace(new RegExp(`%_${key}_%`, 'g'), data[key]);
  }

  return result;
};

export const objectAssign = (target, ...sources) => {
  sources.forEach(source => {
    Object.keys(source || {
    }).forEach(key => {
      const sVal = source[key];
      const tVal = target[key];
      target[key] =
        tVal &&
        sVal &&
        typeof tVal === 'object' &&
        typeof sVal === 'object' ?
          objectAssign(tVal, sVal) :
          sVal === null ?
            tVal :
            sVal;
    });
  });
  return target;
};

export function isValidCard(value) {
  // accept only digits, dashes or spaces
  if (/[^0-9-\s]+/.test(value)) {
    return false;
  }

  let nCheck = 0, nDigit = 0, bEven = false;
  value = value.replace(/\D/g, '');

  for (let n = value.length - 1; n >= 0; n--) {
    let cDigit = value.charAt(n);
    nDigit = parseInt(cDigit, 10);

    if (bEven) {
      if ((nDigit *= 2) > 9) {
        nDigit -= 9;
      }
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  return (nCheck % 10) == 0;
};

export const mobile = () => {
  const MOBILE_WIDTH = 768;
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < MOBILE_WIDTH;

  return isMobile;
}

export function getAnalyticsProductFromLocalStorage(basecode) {
  try {
    if (!localStorage.getItem('analyticsProducts')) {
      return;
    }
    
    const analyticsProductsFromLocalStorage = JSON.parse(localStorage.getItem('analyticsProducts'));
    const foundProduct = analyticsProductsFromLocalStorage.find(item => item.basecode == basecode);
    return foundProduct;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export function setAnalyticsProductToStorage({ pk, idx, basecode, listIdx, listName }) {
  try {
    if (!basecode || !listIdx) {
      return;
    }
    const data = {
      pk,
      idx,
      basecode,
      listIdx,
      listName
    };

    const analyticsProducts = JSON.parse(localStorage.getItem('analyticsProducts')) || [];
    const existingIndex = analyticsProducts && analyticsProducts.findIndex(product => product.basecode == data.basecode);

    if (existingIndex > -1) {
      analyticsProducts.splice(existingIndex, 1, data);
    } else {
      analyticsProducts.push(data);
    }

    localStorage.setItem('analyticsProducts', JSON.stringify(analyticsProducts));
  } catch (e) {
    console.log(e, 'an error while setting analytics product to storage');
  }
}

export const replaceTag = (tag) => {
  const safeText = (function (text) {
    this.textContent = text;
    return this.innerHTML;
  }).bind(document.createElement('div'));

  return safeText(tag);
};