import store from 'shop-packages/redux/store';
import observe, { getValue } from 'shop-packages/redux/connectSelector';
import {
  selectPaymentOptions,
  selectCurrentPaymentOption,
} from 'shop-packages/redux/checkout/selectors';
import {
  setPaymentOption,
} from 'shop-packages/redux/checkout/actions';
import { clearErrors } from 'shop-packages/redux/checkout/reducer';

import CreditCardOption from './payment-options/creditCard';
import FundsTransferOption from './payment-options/fundsTransfer';
import MasterpassCardOption from './payment-options/masterpass';
import GPayOption from './payment-options/gpay';
import PayWithIyzicoOption from './payment-options/iyzico';

class PaymentTab {
  observers = [];
  paymentOptions = new Map([
    ['credit_card', CreditCardOption],
    ['masterpass', MasterpassCardOption],
    ['funds_transfer', FundsTransferOption],
    ['gpay', GPayOption],
    ['redirection', PayWithIyzicoOption]
  ]);

  currentPaymentOption = 'credit_card';
  currentOptionInstance = null;

  constructor() {
    this.$container = $(document.getElementById('PaymentTabs'));
    this.$paymentTabs = this.$container.find('.js-payment-tab');
    this.$paymentTabContents = this.$container.find('.js-payment-tab-content');

    this.$paymentTabs.on('click.paymentTab', this.onPaymentOptionClick.bind(this));

    this.initObservers();
    this.showAvailableButtons();
  }

  onPaymentOptionClick(e) {
    const target = $(e.currentTarget);
    if (!target.hasClass('active')) {
      const { pk } = getValue(selectPaymentOptions).find(
        ({payment_type}) => payment_type === target.data('type')
      );

      store.dispatch(setPaymentOption(pk))
    }
  }

  onPaymentOptionChange(option) {
    $('.js-error-non_field_errors').addClass('d-none');
    if (!option) {
      return;
    }

    store.dispatch(clearErrors());
    this.currentPaymentOption = option;

    if (this.currentOptionInstance) {
      this.currentOptionInstance.unmount();
    }

    this.$paymentTabs.removeClass('active');
    this.$paymentTabContents
      .removeClass('d-flex')
      .removeClass('active');

    this.$paymentTabs
      .filter(`[data-type="${option.payment_type}"]`)
      .addClass('active');

    this.$paymentTabContents
      .filter(`[data-type="${option.payment_type}"]`)
      .addClass('d-flex')
      .addClass('active');

    const paymentOption = this.paymentOptions.get(option.payment_type);
    if (paymentOption) {
      this.currentOptionInstance = new paymentOption(option);
    }
    this.onCheckoutPaymentType(3,$('.js-payment-tab.active').text())
  }

  initObservers() {
    this.observers = [
      observe(selectCurrentPaymentOption).subscribe(this.onPaymentOptionChange.bind(this)),
    ];
  }

  showAvailableButtons() {
    this.$paymentTabs.attr('hidden', true);
    getValue(selectPaymentOptions).forEach(
      ({ payment_type: paymentType }, index) => {
        if (this.paymentOptions.has(paymentType)) {
          $(`.js-payment-tab[data-type="${paymentType}"]`).attr('hidden', false).css({
            order: index
          });
        }
      }
    );
  }

  unmount() {
    if (this.currentOptionInstance) {
      this.$paymentTabs.off('click.paymentTab');
      this.currentOptionInstance.unmount();
    }
    
    for (const observer of this.observers) {
      observer.unsubscribe();
    }
  }

  onCheckoutPaymentType(step,checkoutOption){
    const paymentTypeName = checkoutOption;
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      'event': 'checkoutOption',
        'eventCategory': 'Enhanced Ecommerce',
        'eventAction': 'Checkout Options',
        'eventLabel': paymentTypeName,
        'eventValue': 0,
        'noninteraction': false,
      'ecommerce': {
        'checkout_option': {
          'actionField': {'step': step, 'option': paymentTypeName}
        }
      }
    });

  }
}

export default PaymentTab;