import { CheckoutService } from 'pz-core';
import { store } from 'shop-packages';
import { setPaymentOption } from 'shop-packages/redux/checkout/actions';
import 'jquery-validation';
import observe, { getValue } from 'shop-packages/redux/connectSelector';
import {
  selectAgreement,
  selectPending,
  selectErrors,
  selectPreOrder,
} from 'shop-packages/redux/checkout/selectors';
import { setAgreement, clearErrors } from 'shop-packages/redux/checkout/reducer';
import {
  gettext
} from '../../../locales/gettext';
import {getAnalyticsProductFromLocalStorage } from '../../../utils';

const _messages = {
  required: gettext('Bu alan zorunludur.'),
};
class GPayOption {
  observers = [];
  selectedAccountId = null;
  constructor({ pk }) {
    store.dispatch(setPaymentOption(pk));
    this.$GpayForm = $('.js-gpay-form');
    this.$completeButton = $('.js-checkout-complete-button');
    this.$agreementInput = $('.js-checkout-agreement-input');
    this.$agreementInput.on('input.agreement', this.onAgreementChange.bind(this));
    this.initObservers();
    this.initFundsTransferForm();
  }
  onAgreementChange(e) {
    store.dispatch(
      setAgreement($(e.currentTarget).is(':checked'))
    );
  }
  onAgreementUpdate(agreement) {
    this.$agreementInput.prop('checked', agreement);
  }
  pushEvent() {
    const ecommerce = JSON.parse($('.analytics-address-data').text() || '{}');
    const { items } = ecommerce;

    if (localStorage.basketAnalytics) {
      const basketAnalytics = JSON.parse(localStorage.basketAnalytics);
      items.forEach(item => {
        const analyticProduct = getAnalyticsProductFromLocalStorage(item.item_id);
        const basketItem = basketAnalytics.find(basketItem => basketItem.item_sku === item.item_sku);
        if (basketItem) {
          item.discount_rate = basketItem?.discount_rate;
          item.item_discount_status = basketItem?.item_discount_status
          item.coupon = basketItem?.coupon;
          item.item_last_price = basketItem?.item_last_price
          item.discount =  basketItem?.discount;
        }
        item.item_list_id =  analyticProduct?.listIdx || '';
        item.item_list_name =  analyticProduct?.listName || ''
      });
    }

    const preOrder = getValue(selectPreOrder);

    const paymentInfo = {
      event: 'add_payment_info',
      ecommerce: {
        ...ecommerce,
        currency: 'TRY',
        payment_type: preOrder.payment_option?.name || 'Kredi Kartı',
        value: Number(preOrder.total_amount)
      }
    };

    if (window.isMobileApp) {
      window.ReactNativeWebView?.postMessage(JSON.stringify(paymentInfo));
      return;
    }

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(paymentInfo);
  }

  initFundsTransferForm() {
    const _self = this;
    this.validator = this.$GpayForm.validate({
      submitHandler: (_, event) => {
        event.preventDefault;
        store.dispatch(clearErrors());
        _self.onPendingUpdate(true);
        CheckoutService.setGPay().then(() => CheckoutService.completeGPay().then((response) => {
          const { data } = response;
          const redirectUrl = data.context_list?.[0]?.page_context?.redirect_url;
          if (!redirectUrl) {
            _self.onErrorsUpdate({ general: gettext('Bir sorun oluştu. Lütfen daha sonra tekrar deneyin.') });
            return;
          }

          try {
            this.pushEvent();
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error in pushEvent:', error);
          }

          window.location = redirectUrl;
        })).finally(() => {
          _self.onPendingUpdate(false);
        });
        return false;
      },
      unhighlight: (element) => {
        element = $(element);
        element.parent().siblings('.js-form-error').attr('hidden', true)
          .html('');
        if (element[0].classList.contains('js-checkout-agreement-input')) {
          $('.js-error-aggreement').html('').attr('hidden', true);
        }
      },
      errorPlacement: (error, element) => {
        const errorText = error[0].innerText;
        if (element[0].classList.contains('js-checkout-agreement-input')) {
          $('.js-error-aggreement').html(error.html()).attr('hidden', false);
        }
        element.parent().siblings('.js-form-error').attr('hidden', errorText.length <= 0)
          .html(errorText);
      },
      ignore: [],
      rules: {
        agreement: {
          required: true,
          normalizer: () => getValue(selectAgreement),
        }
      },
      messages: {
        agreement: {
          required: _messages.required,
        }
      }
    });
  }
  onPendingUpdate(isPending) {
    isPending ?
      this.$completeButton
        .attr('disabled', 'disabled')
        .addClass('loading') :
      this.$completeButton
        .removeAttr('disabled')
        .removeClass('loading');
  }
  onErrorsUpdate(errors) {
    $('.js-error-*').text('').hide();
    for (const key in errors) {
      $(`.js-error-${key}`).text(errors[key]).show();
    }
  }
  initObservers() {
    // eslint-disable-next-line max-len
    this.observers = [observe(selectAgreement).subscribe(this.onAgreementUpdate.bind(this)), observe(selectPending).subscribe(this.onPendingUpdate.bind(this)), observe(selectErrors).subscribe(this.onErrorsUpdate.bind(this))];
  }
  unmount() {
    this.$agreementInput.off('input.agreement');
    store.dispatch(setAgreement(false));
    if (this.validator) {
      this.validator.destroy();
    }
    for (const observer of this.observers) {
      observer.unsubscribe();
    }
  }
}
export default GPayOption;